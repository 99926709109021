<template>
  <div class="bodypage ranking">
    
    <div class="user-ranking">
      <div class="headlogo">
        <div class="row">
        <div class="col head">
          <h1>Classifica</h1>
        </div>
        <div class="col head-logo">
          <img src="@/assets/img/logo_rev.svg" alt="Notaropoly" class="logo-image">
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div v-for="(user, index) in usersScores" :key="user.matchId" :class="`user-${getPostion(index)}`" class="user col-12 col-sm-6">
            <div class="score">
              {{ getPostion(index) }}
            </div>
            <!-- Assegna fisicamente un valore a index -->
            <img :src='(user.avatarFileName == null || String(user.avatarFileName).length == 0) ? require(`@/assets/img/default-avatar-profile.jpg`) : require(`@/assets/img/${user.avatarFileName}`)' alt="Profile Image" class="profile-image" :style="{ borderColor: user.userColor }">
            <div class="user-info">
              <div class="medal-box">
                <img src="@/assets/img/medal.svg" alt="medal" class="medal-image">
              </div>
              <h3>{{ user.name }}</h3>
              <p>Punteggio: {{ (user.score)?.toFixed(3) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <button @click="navigator('previous')" :disabled="paginationRequest.page == 1" class="btn">Precedente</button>
        <span>{{ paginationRequest.page }} di {{  Math.ceil(totalResults / paginationRequest.perPage!) }}</span>
        <button @click="navigator('next')" :disabled="(paginationRequest.page! * paginationRequest.perPage!) > totalResults" class="btn">Successivo</button>
      </div>
      <div class="footer">
        <button @click="repeatGame" class="me-button repeat-button">Gioca una nuova partita</button>
        <button @click="logOut" class="me-button repeat-button exit-button">Esci</button>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { ref, onMounted, reactive } from 'vue';
import { CreateAxiosInstance } from '@/services/auth.module';
import router from '@/router';  // Assicurati che il percorso del router sia corretto
import { MatchService, UserScore, serviceOptions, Ranking, PaginationRequest } from '@/services/swagger';
import store from '@/store';
import { AxiosError } from 'axios';
serviceOptions.axios = CreateAxiosInstance();
const usersScores = ref<UserScore[]>([]);
const paginationRequest = reactive<PaginationRequest>({
  page: 1,
  perPage: 10
})
const getPostion = (index:number) => {
  if(!paginationRequest.page) paginationRequest.page = 1;
  if(!paginationRequest.perPage) paginationRequest.perPage = 10;
  var page = paginationRequest.page - 1;
  var perPage = paginationRequest.perPage
  return (index + 1) + (page * perPage);
}
const totalResults = ref(0);
const navigator = async (action:string) => {
  if(!paginationRequest.page) paginationRequest.page = 1;
  if(!paginationRequest.perPage) paginationRequest.perPage = 10;
  if(action == 'next'){
    if(totalResults.value > (paginationRequest.perPage * paginationRequest.page))
    {
      paginationRequest.page += 1;
    }
  }else if (action == 'previous'){
    if(paginationRequest.page == 1){
      return;
    }
    paginationRequest.page -= 1;
  }
  await GetRanking();
}
const repeatGame = async () => {
  try{
    await MatchService.new();
    router.push({name: "map"})
  }catch(err:unknown){
    if(err instanceof AxiosError){
      if(err.response?.status == 423){
        alert("Numero massimo di tentativi superato");
      }else{
        alert("Impossibile avviare una nuova partita!")
      }
    }
  }
};

const GetRanking = async () =>{
  try{
    var rankingRes:Ranking = await MatchService.score({body:paginationRequest})
    usersScores.value = rankingRes.usersScores as UserScore[];
    totalResults.value = rankingRes.totalResults || 0
  }
  catch(err){
    console.log(err);
    
  }
}

const logOut = () => {
  store.commit("UserModule/logOut");
}

onMounted(async()=>{
  await GetRanking();
})

</script>

<style scoped>
  .user-ranking {
    width: 80%;
    padding: 4em;
    max-height: 100%;
    background-color: #81a9da;
    margin: 20px auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }

  .user {
    display: flex;
    align-items: center;
    padding-left: 40px;
    margin-bottom: 0!important;
    padding-bottom: 0!important;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .user-ranking .user:nth-child(1) {
    font-size: 1.5em;
    padding-left: 20px;
  }

  .user-ranking .user:nth-child(2) {
    font-size: 1.2em;
  }

  .user-ranking .user:nth-child(3) {
    font-size: 1.1em;
  }

  .user-ranking .user {
    font-size: 0.7em;
  }

  .user-ranking .user:nth-child(1) .score {
    padding-right: 40px;
  }

  .profile-image {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
    border: 1px solid black;
  }

  .user-info {
    vertical-align: top;
    margin-top: 2em;
  }

  .user-info h3 {
    font-size: 2em;
  }

  .user-info p {
    margin-bottom: 0;
  }

  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  button {
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .repeat-button {
    margin-top: 20px;
    background-color: #014482;
  }

  .repeat-button:hover {
    background-color: #159ffc;
  }

  .score {
    font-size: 3.5em;
    padding-right: 20px;
    color: white;
  }

  img.logo-image {
    max-width: 200px;
  }

  .col.head-logo {
    text-align: right;
  }

  .col.head h1 {
    font-size: 2.5em;
    font-weight: bold;
    color: #014482;
  }

  .bodypage {
    background-image: url("@/assets/img/fondo.jpg");
    background-color: #0b4d86!important;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
  }

  .footer {
    width: 100%;
    text-align: center;
    padding-top: 1em;
  }

  .user-ranking .user:nth-child(1) h3 {
    text-shadow: 5px 0px 10px #ffd05c;
    color: #ffe000;
     background: -webkit-linear-gradient(#fff600, #b8a000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold
  }

  .user-ranking .user:nth-child(2) h3 {
    text-shadow: 5px 0px 10px #ffffff52;
    color: #d6d6d6;
    background: -webkit-linear-gradient(#ffffff, #999999);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  .user-ranking .user:nth-child(3) h3 {
    text-shadow: 0px 0px 10px #835d006b;
    color: #ff6f00;
    background: -webkit-linear-gradient(#ff8604, #ff7c00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  .score {
    min-width: 80px
;}

.user-info img.medal-image {
    display: none;
    max-height: 1em;
}
.medal-box {display: block;float: right;width: 1em;}

.user-ranking .user h3 {
    display: block;
    float: left;
}

.user:nth-child(1) .medal-image, .user:nth-child(2) .medal-image, .user:nth-child(3) .medal-image{display: inline-block;}




.pagination .btn {
    border: 1px solid white!important;
    color: white;
    background-color: transparent;
}

.pagination button.btn[disabled] {
    background-color: #80808055;
    color: #c9c9c9;
}

.pagination span {
    color: white;
    display: inline-block;
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
}

button.btn:hover {
    background-color: #3498db;
}
@media only screen and (max-width: 600px) {
  .bodypage.ranking {
      font-size: 2em;
      position: relative;
  }

  .bodypage.ranking img.logo-image {
      max-width: 100%;
  }

}
</style>

